import React from 'react'
import { Heading, Flex, Box, Container, Text, ListItem, UnorderedList, Link, ExternalLinkIcon } from '@chakra-ui/react'
import Header from '../components/header'
// import LeadFile from '../downloads/Hogarth_Casting_Declaration_Part_1_2019_2nd_Foot.docx'

export default function Home() {
        const Project = 'NT1001'
        const BASECAMP_PROJECT_ID = '20475191'

        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                Self Tape Submissions
                        </Heading>
                        <Container mb={4} padding={1} align="center">
                                <Heading as="h2" fontSize="2xl" m="2" textTransform="uppercase">
                                        {Project}: Casting for Lamisil TV Commercial
                                </Heading>
                                <Box mb={8}>
                                        <Heading as="h3" fontSize="l" m="2" textTransform="uppercase" mt={8}>
                                                The Deadline has now passed for this project
                                        </Heading>
                                </Box>
                        </Container>
                </>
        )
}
